<template>
  <!-- 运维 - 模板 - 使用模板统计 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招生工具模板</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">使用模板统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="模板名称" class="searchboxItem ci-full">
              <span class="itemLabel">模板名称:</span>
              <el-input
                v-model="retrievalData.templateName"
                type="text"
                size="small"
                class="unitNamewidth"
                placeholder="请输入模板名称"
                clearable
              />
            </div>
            <div title="模板类型" class="searchboxItem ci-full">
              <span class="itemLabel" >模板类型:</span>
              <el-select
                v-model="retrievalData.templateTypeId"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in template_type_data"
                  :key="item.templateTypeId"
                  :label="item.typeName"
                  :value="item.templateTypeId"
                ></el-option>
              </el-select>
            </div>
            <div title="是否上架" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:5rem">是否上架:</span>
              <el-select
                v-model="retrievalData.sellState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in sellStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="直接使用次数" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">直接使用次数:</span>
              <el-input
                v-model="retrievalData.directUseNumberStart"
                @keyup.native="directUseNumberStart = zF.positiveInteger(directUseNumberStart)"
                placeholder="请输入"
                size="small"
                 clearable
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="retrievalData.directUseNumberEnd"
                @keyup.native="directUseNumberEnd = zF.positiveInteger(directUseNumberEnd)"
                placeholder="请输入"
                size="small"
                 clearable
                class="sjd"
              ></el-input>
            </div>
           
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
             <div title="使用次数" class="searchboxItem ci-full">
              <span class="itemLabel">使用次数:</span>
              <el-input
                v-model="retrievalData.useNumberStart"
                @keyup.native="useNumberStart = zF.positiveInteger(useNumberStart)"
                placeholder="请输入"
                size="small"
                clearable
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="retrievalData.useNumberEnd"
                @keyup.native="useNumberEnd = zF.positiveInteger(useNumberEnd)"
                placeholder="请输入"
                size="small"
                 clearable
                class="sjd"
              ></el-input>
            </div>
           
            <div title="收藏次数" class="searchboxItem ci-full">
              <span class="itemLabel">收藏次数:</span>
              <el-input
                v-model="retrievalData.likeNumberStart"
                @keyup.native="likeNumberStart = zF.positiveInteger(likeNumberStart)"
                placeholder="请输入"
                size="small"
                 clearable
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="retrievalData.likeNumberEnd"
                @keyup.native="likeNumberEnd = zF.positiveInteger(likeNumberEnd)"
                placeholder="请输入"
                size="small"
                 clearable
                class="sjd"
              ></el-input>
            </div>
            <div title="报名人数" class="searchboxItem ci-full">
              <span class="itemLabel" >报名人数:</span>
              <el-input
                v-model="retrievalData.applyNumberStart"
                @keyup.native="applyNumberStart = zF.positiveInteger(applyNumberStart)"
                placeholder="请输入"
                size="small"
                 clearable
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="retrievalData.applyNumberEnd"
                @keyup.native="applyNumberEnd = zF.positiveInteger(applyNumberEnd)"
                placeholder="请输入"
                size="small"
                 clearable
                class="sjd"
              ></el-input>
            </div>
             <div title="修改使用次数" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">修改使用次数:</span>
              <el-input
                v-model="retrievalData.modifyUseNumberStart"
                @keyup.native="modifyUseNumberStart = zF.positiveInteger(modifyUseNumberStart)"
                placeholder="请输入"
                size="small"
                 clearable
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="retrievalData.modifyUseNumberEnd"
                @keyup.native="modifyUseNumberEnd = zF.positiveInteger(modifyUseNumberEnd)"
                placeholder="请输入"
                size="small"
                 clearable
                class="sjd"
              ></el-input>
            </div>
          </div>
          <div class="searchbox">
            <div title="创建时间" class="searchboxItem ci-full">
              <span class="itemLabel">创建时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="retrievalData.createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="模板名称"
                align="left"
                prop="templateName"
                show-overflow-tooltip
                min-width="220"
              />
              <el-table-column
                label="模板类型"
                align="left"
                prop="templateTypeName"
                show-overflow-tooltip
              />
              <el-table-column
                label="使用次数"
                align="left"
                prop="useNumber"
                show-overflow-tooltip
              />
              <el-table-column
                label="直接使用次数"
                align="left"
                prop="directUseNumber"
                show-overflow-tooltip
              />
              <el-table-column
                label="修改使用次数"
                align="left"
                prop="modifyUseNumber"
                show-overflow-tooltip
              />
              <el-table-column
                label="收藏次数"
                align="left"
                prop="likeNumber"
                show-overflow-tooltip
              />
              <el-table-column
                label="报名人数"
                align="left"
                prop="applyNum"
                show-overflow-tooltip
              />
              <el-table-column
                label="创建时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                minWidth="150"
              >
              <template slot-scope="scope">
                {{scope.row.createTime | moment}}
              </template>
              </el-table-column>
              <el-table-column
                label="是否上架"
                align="left"
                prop="sellState"
                show-overflow-tooltip
              >
              <template slot-scope="scope">
                {{scope.row.sellState ? '上架':'下架'}}
              </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "UnitList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        templateName: "", // 机构名称
        templateTypeId: "", // 模板类型
        sellState: "", // 是否上架
        directUseNumberStart: "", // 直接使用次数 - 小
        directUseNumberEnd: "", // 直接使用次数 - 大
        useNumberStart: "", // 使用次数 - 小
        useNumberEnd: "", // 使用次数 - 大
        modifyUseNumberStart: "", // 修改使用次数 - 小
        modifyUseNumberEnd: "", // 修改使用次数 - 大
        likeNumberStart: "", // 收藏次数 - 小
        likeNumberEnd: "", // 收藏次数 - 大
        applyNumberStart: "", // 报名人数 - 小
        applyNumberEnd: "", // 报名人数 - 大
        createTime:'', // 创建时间
      },
      sellStateList:[
        {
          value:true,
          label:'上架'
        },
        {
          value:false,
          label:'下架'
        }
      ],
      template_type_data:[],
    };
  },
  created() {
    this.get_Template_type()
  },
  computed: {},
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        sellState:this.retrievalData.sellState
      };
      //模版名称
      if(this.retrievalData.templateName) {
        params.templateName = this.retrievalData.templateName;
      }
      //模版类型
      if(this.retrievalData.templateTypeId) {
        params.templateTypeId = this.retrievalData.templateTypeId;
      }
       if (
        (this.retrievalData.directUseNumberStart && !this.retrievalData.directUseNumberEnd) ||
        (!this.retrievalData.directUseNumberStart && this.retrievalData.directUseNumberEnd)
      ) {
        this.$message.warning("请补全直接使用次数区间");
        return false;
      }
      if (this.retrievalData.directUseNumberStart && this.retrievalData.directUseNumberEnd) {
        if (Number(this.retrievalData.directUseNumberStart) > Number(this.retrievalData.directUseNumberEnd)) {
          this.$message.warning("直接使用次数不能大于截止直接使用次数");
          return false;
        } else {
          params.directUseNumberStart = this.retrievalData.directUseNumberStart;
          params.directUseNumberEnd = this.retrievalData.directUseNumberEnd;
        }
      }
      //使用次数
       if (
        (this.retrievalData.useNumberStart && !this.retrievalData.useNumberEnd) ||
        (!this.retrievalData.useNumberStart && this.retrievalData.useNumberEnd)
      ) {
        this.$message.warning("请补全使用次数区间");
        return false;
      }
      if (this.retrievalData.useNumberStart && this.retrievalData.useNumberEnd) {
        if (Number(this.retrievalData.useNumberStart) > Number(this.retrievalData.useNumberEnd)) {
          this.$message.warning("使用次数不能大于截止使用次数");
          return false;
        } else {
          params.useNumberStart = this.retrievalData.useNumberStart;
          params.useNumberEnd = this.retrievalData.useNumberEnd;
        }
      }
      //修改使用次数
       if (
        (this.retrievalData.modifyUseNumberStart && !this.retrievalData.modifyUseNumberEnd) ||
        (!this.retrievalData.modifyUseNumberStart && this.retrievalData.modifyUseNumberEnd)
      ) {
        this.$message.warning("请补全修改使用次数区间");
        return false;
      }
      if (this.retrievalData.modifyUseNumberStart && this.retrievalData.modifyUseNumberEnd) {
        if (Number(this.retrievalData.modifyUseNumberStart) > Number(this.retrievalData.modifyUseNumberEnd)) {
          this.$message.warning("修改使用次数不能大于截止修改使用次数");
          return false;
        } else {
          params.modifyUseNumberStart = this.retrievalData.modifyUseNumberStart;
          params.modifyUseNumberEnd = this.retrievalData.modifyUseNumberEnd;
        }
      }
      //收藏次数
       if (
        (this.retrievalData.likeNumberStart && !this.retrievalData.likeNumberEnd) ||
        (!this.retrievalData.likeNumberStart && this.retrievalData.likeNumberEnd)
      ) {
        this.$message.warning("请补全收藏次数区间");
        return false;
      }
      if (this.retrievalData.likeNumberStart && this.retrievalData.likeNumberEnd) {
        if (Number(this.retrievalData.likeNumberStart) > Number(this.retrievalData.likeNumberEnd)) {
          this.$message.warning("收藏次数不能大于截止收藏次数");
          return false;
        } else {
          params.likeNumberStart = this.retrievalData.likeNumberStart;
          params.likeNumberEnd = this.retrievalData.likeNumberEnd;
        }
      }
      //报名次数
       if (
        (this.retrievalData.applyNumberStart && !this.retrievalData.applyNumberEnd) ||
        (!this.retrievalData.applyNumberStart && this.retrievalData.applyNumberEnd)
      ) {
        this.$message.warning("请补全报名人数区间");
        return false;
      }
      if (this.retrievalData.applyNumberStart && this.retrievalData.applyNumberEnd) {
        if (Number(this.retrievalData.applyNumberStart) > Number(this.retrievalData.applyNumberEnd)) {
          this.$message.warning("报名人数不能大于截止报名人数");
          return false;
        } else {
          params.applyNumberStart = this.retrievalData.applyNumberStart;
          params.applyNumberEnd = this.retrievalData.applyNumberEnd;
        }
      }
      if (this.createTime) {
        params.createTimeStart = this.createTime[0];
        params.createTimeEnd = this.createTime[1];
      }
      this.doFetch({
        url: "/design/run/templateUseStatistics/pageList",
        params,
        pageNum,
      });
    },
    // 获取模板类型下拉数据
    get_Template_type() {
      this.$post("/design/run/template/getTemplateTypeList").then((res) => {
        this.template_type_data = res.data;
      });
    },
  },
  watch: {},
};
</script>
<style lang="less">
.sjd {
  width: 5.2rem;
  input {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
  }
}
</style>
